import axios from '@/util/request'

export function getTV(queryInfo) {
    return axios({
        url: 'television/get',
        method: 'GET',
        params: {
            ...queryInfo
        }
    })
}

export function getCategoryAndType() {
    return axios({
        url: 'get/categoryAndType',
        method: 'GET'
    })
}

export function getTVById(id) {
    return axios({
        url: 'television/getTVById',
        method: 'GET',
        params: {
            id
        }
    })
}

export function addTV(data) {
    return axios({
        url: `television/add`,
        method: 'POST',
        data: {
            ...data
        }
    })
}

export function updateTV(tv) {
    return axios({
        url: `television/update`,
        method: 'POST',
        data: {
            ...tv
        }
    })
}

export function deleteTVById(id) {
    return axios({
        url: `television/delete`,
        method: 'DELETE',
        params: {
            id: id
        }

    })
}