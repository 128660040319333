<template>
    <div>
        <!--搜索-->
        <el-row>
            <el-col :span="8">
                <el-input placeholder="请输入标题" v-model="queryInfo.name" :clearable="true" @clear="search"
                          @keyup.native.enter="search" size="small" style="min-width: 500px">
                    <el-select v-model="queryInfo.categoryId" slot="prepend" placeholder="请选择分类" :clearable="true"
                               @change="search" style="width: 160px">
                        <el-option :label="item.name" :value="item.id" v-for="item in categoryList"
                                   :key="item.id"></el-option>
                    </el-select>

                    <el-button slot="append" icon="el-icon-search" @click="search"></el-button>
                </el-input>
            </el-col>
            <el-col :span="3" :offset="13">
                <el-select v-model="queryInfo.watch"  placeholder="请选择观看情况" :clearable="true"
                           @change="search">
                    <el-option :label="item.label" :value="item.value" v-for="item in watch"
                               :key="item.value"></el-option>
                </el-select>
            </el-col>
        </el-row>

        <el-table :data="tvList">
            <el-table-column label="序号" type="index" width="50" align="center"></el-table-column>
            <el-table-column label="名称" prop="name" show-overflow-tooltip align="center"></el-table-column>
            <el-table-column label="分类" prop="category.name" width="150" align="center"></el-table-column>
            <el-table-column label="创建时间" width="200" align="center">
                <template v-slot="scope">{{ scope.row.create_time }}</template>
            </el-table-column>
            <el-table-column label="最近更新" width="200" align="center">
                <template v-slot="scope">{{ scope.row.update_time }}</template>
            </el-table-column>
            <el-table-column label="操作" width="200" align="center">
                <template v-slot="scope">
                    <el-button type="primary" icon="el-icon-edit" size="mini" @click="goTVEdit(scope.row.id)">编辑
                    </el-button>
                    <el-popconfirm title="确定删除吗？" icon="el-icon-delete" iconColor="red"
                                   @confirm="deleteTVById(scope.row.id)">
                        <el-button size="mini" type="danger" icon="el-icon-delete" slot="reference">删除</el-button>
                    </el-popconfirm>
                </template>
            </el-table-column>
        </el-table>

        <!--分页-->
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                       :current-page="queryInfo.pageNum"
                       :page-sizes="[10, 20, 30, 50]" :page-size="queryInfo.pageSize" :total="total"
                       layout="total, sizes, prev, pager, next, jumper" background>
        </el-pagination>
    </div>
</template>

<script>
    import {getTV, deleteTVById} from '@/api/television'

    export default {
        name: "TVList",
        data() {
            return {
                queryInfo: {
                    name: '',
                    categoryId: null,
                    pageNum: 1,
                    pageSize: 10,
                    watch:null
                },
                tvList: [],
                categoryList: [],
                total: 0,
                dialogVisible: false,
                radio: 1,
                visForm: {
                    appreciation: false,
                    recommend: false,
                    commentEnabled: false,
                    top: false,
                    published: false,
                    password: '',
                },
                watch: [
                    {
                        value: 0,
                        label: '在看'
                    }, {
                        value: 1,
                        label: '未看'
                    }, {
                        value: 2,
                        label: '看完'
                    }
                ]
            }
        },
        watch: {
            $route(){
                console.log('route');
                this.tvList = this.categoryList = this.total = ''
            }             
        },
        created() {
            this.getData()
        },
        methods: {
            getData() {
                getTV(this.queryInfo).then(res => {
                    this.tvList = res.data.list
                    this.categoryList = res.data.categories
                    this.total = res.data.total
                })
            },
            search() {
                this.queryInfo.pageNum = 1
                this.queryInfo.pageSize = 10
                this.getData()
            },

            //监听 pageSize 改变事件
            handleSizeChange(newSize) {
                this.queryInfo.pageSize = newSize
                this.getData()
            },
            //监听页码改变的事件
            handleCurrentChange(newPage) {
                this.queryInfo.pageNum = newPage
                this.getData()
            },
            goTVEdit(id) {
                this.$router.push(`/page/edit/${id}`)
            },
            deleteTVById(id) {
                deleteTVById(id).then(res => {
                    this.msgSuccess(res.message)
                    this.getData()
                })
            }
        }
    }
</script>

<style scoped>

</style>